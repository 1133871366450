const state = {
    userCountry: localStorage.getItem('user-country') || null,
    userEmail: localStorage.getItem('user-email') || null,
    userLanguage: localStorage.getItem('user-language') || null,
    userPhone: localStorage.getItem('user-phone') || null,
    userPlan: localStorage.getItem('user-plan') || null,
}

const getters = {
    user: function (state) {
        return {
            country: state.userCountry,
            email: state.userEmail,
            language: state.userLanguage,
            phone: state.userPhone,
            plan: state.userPlan,
        }
    },
}

const mutations = {
    removeUser (state) {
        localStorage.removeItem('user-country')
        localStorage.removeItem('user-email')
        localStorage.removeItem('user-language')
        localStorage.removeItem('user-phone')
        localStorage.removeItem('user-plan')
        state.userCountry = null
        state.userEmail = null
        state.userLanguage = null
        state.userPhone = null
        state.userPlan = null
    },
    updateUser (state, user) {
        localStorage.setItem('user-country', user.country)
        localStorage.setItem('user-email', user.email)
        localStorage.setItem('user-language', user.language)
        localStorage.setItem('user-phone', user.phone)
        localStorage.setItem('user-plan', user.plan)
        state.userCountry = user.country
        state.userEmail = user.email
        state.userLanguage = user.language
        state.userPhone = user.phone
        state.userPlan = user.plan
    },
    updateUserCountry (state, country) {
        localStorage.setItem('user-country', country)
        state.userCountry = country
    },
    updateUserLanguage (state, language) {
        localStorage.setItem('user-language', language)
        state.userLanguage = language
    },
}

export default {
    state,
    getters,
    mutations,
}
