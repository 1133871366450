<template>
    <transition name="fade" mode="out-in">
        <v-container class="mb-6 mb-md-12">
            <nav class="breadcrumbs mt-n2 mt-md-n11" role="navigation" aria-labelledby="breadcrumbs_heading">
                <ol class="breadcrumbs__list">
                    <li class="breadcrumbs__item">
                        <router-link to="/" class="breadcrumbs__link link link--underscore">
                            <svg width="18px" height="16px" class="breadcrumbs__house" role="img">
                                <use xlink:href="@/assets/images/icons/icons.svg#icon-house"></use>
                            </svg>
                            <span>Главная</span>
                        </router-link>
                        <svg width="13px" height="13px" class="breadcrumbs__chevron" role="img">
                            <use xlink:href="@/assets/images/icons/icons.svg#icon-chevron_right"></use>
                        </svg>
                    </li>
                    <li class="breadcrumbs__item">
                        <span class="breadcrumbs__link" aria-current="location">Статьи</span>
                    </li>
                </ol>
            </nav>

            <h2 class="section__title mb-6 mb-md-10">Статьи</h2>
            <v-row id="articles" class="articles flex-wrap">
                <v-col
                    v-for="(a, i) in articles"
                    :key="i"
                    cols="12"
                    md="4"
                    lg="3"
                    class="article"
                >
                    <router-link :to="a.url" class="link d-flex flex-md-column">
                        <div class="article__image">
                            <img :src="'/api/landing/article/image/' + a.image" alt="Article image" />
                        </div>
                        <h6 class="article__title" :title="a.title">{{ a.title }}</h6>
                    </router-link>
                </v-col>
            </v-row>

            <v-row v-show="false">
                <v-col cols="12">
                    <ul class="pagination">
                        <li class="pagination__prev">
                            <router-link to="" class="pagination__link link disabled">
                                <svg width="20px" height="20px" class="pagination__chevron" role="img">
                                    <use xlink:href="@/assets/images/icons/icons.svg#icon-chevron_left"></use>
                                </svg>
                            </router-link>
                        </li>
                        <li class="pagination__item">
                            <router-link to="" class="pagination__link link disabled">1</router-link>
                        </li>
                        <li class="pagination__item active">
                            <!-- active -->
                            <router-link to="" class="pagination__link link link--primary">2</router-link>
                        </li>
                        <li class="pagination__item">
                            <router-link to="" class="pagination__link link">3</router-link>
                        </li>
                        <li class="pagination__item">
                            <router-link to="" class="pagination__link link">4</router-link>
                        </li>
                        <li class="pagination__dots">...</li>
                        <li class="pagination__item">
                                <router-link to="" class="pagination__link link">10</router-link>
                            </li>
                        <li class="pagination__next">
                            <router-link to="" class="pagination__link link">
                                <svg width="20px" height="20px" class="pagination__chevron" role="img">
                                    <use xlink:href="@/assets/images/icons/icons.svg#icon-chevron_right"></use>
                                </svg>
                            </router-link>
                        </li>
                    </ul>
                </v-col>
            </v-row>
        </v-container>
    </transition>
</template>

<script>
export default {
    name: "ArticlesController",
    metaInfo () {
        return {
            title: this.meta.title,
            meta: [
                {name: 'description', property: 'og:description', content: this.meta.description},
                {name: 'keywords', content: this.meta.keywords},
            ],
        }
    },
    data () {
        return {
            articles: null,
            loading: true,
            meta: {
                description: '',
                keywords: '',
                title: '',
            },
        }
    },
    created () {
        this.searchArticles()
        this.searchMeta()
    },
    methods: {
        searchArticles () {
            this.loading = true

            this.$axios.post('/api/landing/article', {url: this.$router.currentRoute.path})
                .then(res => {
                    if (res.data.success) {
                        this.articles = res.data.data
                    }

                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        },

        searchMeta () {
            this.$axios.post('/api/landing/article/meta', {url: this.$router.currentRoute.path})
                .then(res => {
                    if (res.data.success) {
                        this.meta = res.data.data
                    }
                })
                .catch(() => {})
        },
    },
}
</script>
