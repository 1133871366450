import Vue from 'vue'
import VueMeta from 'vue-meta'
import Router from 'vue-router'
import Agreement from '../components/controllers/AgreementController.vue'
import Article from '../components/controllers/ArticleController.vue'
import Articles from '../components/controllers/ArticlesController.vue'
import Default from '../components/controllers/DefaultController.vue'
import Feedback from '../components/controllers/FeedbackController.vue'
import Message from '../components/controllers/MessageController.vue'
import HowItWorks from '../components/controllers/HowItWorksController.vue'
import NotFound from '../components/controllers/NotFoundController.vue'
import PrivacyPolicy from '../components/controllers/PrivacyPolicyController.vue'

Vue.use(Router)
Vue.use(VueMeta)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior (to, from, savedPosition) {
        if (to.hash) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({ y: document.querySelector(to.hash).offsetTop, behavior: 'smooth' })
                }, 350);
            })
        } else {
            return savedPosition || new Promise((resolve) => {
                setTimeout(() => {
                    resolve({ y: 0, behavior: 'smooth' })
                }, 200);
            })
        }
    },
    routes: [
        {
            path: '/',
            name: 'default',
            component: Default,
        },
        {
            path: '/feedback',
            name: 'feedback',
            component: Feedback,
        },
        {
            path: '/agreement',
            name: 'agreement',
            component: Agreement,
        },
        {
            path: '/message',
            name: 'message',
            component: Message,
        },
        {
            path: '/message/:hash',
            name: 'message-hash',
            component: Message,
        },
        {
            path: '/privacy',
            name: 'privacy',
            component: PrivacyPolicy,
        },
        {
            path: '*',
            name: 'article',
            component: Article,
        },
        {
            path: '/articles',
            name: 'articles',
            component: Articles,
        },
        {
            path: '/how-it-works',
            name: 'howItWorks',
            component: HowItWorks,
        },
        {
            path: '/404',
            name: 'notFound',
            component: NotFound,
        },
        {
            path: '*',
            redirect: '/404',
        },
    ]
})
