const state = {
    language: localStorage.getItem('language') || null,
}

const getters = {
    language: function (state) {
        return state.language || 'en_US'
    },
}

const mutations = {
    updateLanguage (state, language) {
        localStorage.setItem('language', language)
        state.language = language
    },
}

export default {
    state,
    getters,
    mutations,
}
