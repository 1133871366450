<template>
    <v-app>
        <!-- <v-app-bar app flat clipped-right color="grey lighten-4">
            <v-spacer/>
            <v-btn
                to="/message"
            >
                Доступ к сообщению
            </v-btn>
            <v-spacer/>
            <v-menu eager v-if="language">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                    >
                        {{ language.name }}
                    </v-btn>
                </template>
                <v-list dense max-height="300px" z-index="100000">
                    <v-list-item
                        v-for="(l, i) in languages"
                        :key="i"
                        @click="updateLanguage(l)"
                    >
                        <v-list-item-title>{{ l.name }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar> -->

        <!-- <v-main :key="languageISO"> -->
        <v-main :key="this.$route.path">
            <TheHeader :hasHeaderBackground="this.$route.path === '/' ? false : true" />
            <router-view />
            <TheFooter :isBackgroundWhite="this.$route.path === '/' ? true : false" />
        </v-main>
    </v-app>
</template>

<script>
import TheHeader from './components/app/TheHeader'
import TheFooter from './components/app/TheFooter'

export default {
    name: 'App',
    components: {
        TheHeader,
        TheFooter,
    },
}
</script>

<style lang="scss">
    @import "./scss/main.scss";
</style>

<style scoped lang="scss">
.v-btn--active {
    opacity: 1 !important;
}

.menuable__content__active {
    .v-list-item {
        background-color: #ffffff;
    }
}
</style>
