<template>
    <v-container
        fluid
        :fill-height="['init', 'confirm', 'blocked', 'wait'].indexOf(stage) >= 0"
    >
        <v-row
            v-if="['init', 'confirm', 'blocked', 'wait'].indexOf(stage) >= 0"
            align="center"
            justify="center"
            class="mt-6 mb-10"
        >
            <v-col xs="12" sm="6" md="4">
                <v-card class="elevation-12">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>Сообщение</v-toolbar-title>
                    </v-toolbar>
                    <v-form autocomplete="off" @submit.prevent="validate">
                        <v-card-text>
                            <p v-if="stage === 'blocked'" class="mt-5 text-center">
                                Доступ по коду заблокирован автором сообщения
                            </p>
                            <p v-if="stage === 'wait'" class="mt-5">
                                Отправителю отправлен запрос на подтверждение прочтения этого Послания. Если Отправитель не заблокирует такую возможность, через {{ waitHours }} часа(ов) блокировка будет снята и с использованием этого же кода Послание станет доступно для прочтения.
                            </p>
                            <p v-if="stage === 'confirm'" class="mt-5">
                                Мы отправили вам код подтверждения
                            </p>
                            <v-text-field
                                v-if="stage === 'init'"
                                v-model="data.hash1"
                                :error-messages="errors.hash1"
                                prepend-icon="mdi-lock"
                                name="hash1"
                                label="Код доступа"
                            />
                            <v-text-field
                                v-if="stage === 'confirm'"
                                v-model="data.hash2"
                                :error-messages="errors.hash2"
                                prepend-icon="mdi-lock"
                                name="hash2"
                                label="Код подтверждения"
                            />
                            <vue-recaptcha
                                ref="recaptcha"
                                size="invisible"
                                :sitekey="siteKey"
                                @verify="read"
                                @expired="onCaptchaExpired"
                            />
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn
                                    v-if="['blocked', 'wait'].indexOf(stage) === -1"
                                    :disabled="wait"
                                    :class="[{'js-wait': wait}]"
                                    type="submit"
                                    color="primary"
                                >Проверить</v-btn>
                            </v-card-actions>
                        </v-card-text>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
        <v-row
            v-if="stage === 'read'"
            justify="center"
        >
            <v-col xs="12" sm="12" md="12">
                <v-card class="elevation-12">
                    <v-toolbar color="grey lighten-4">
                        <v-toolbar-title>Сообщения</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <FsLightbox
                            :key="gallery.key"
                            :toggler="gallery.toggler"
                            :slide="gallery.slide"
                            :sources="gallery.sources"
                            :disableLocalStorage="true"
                            type="image"
                        />
                        <v-expansion-panels>
                            <v-expansion-panel
                                v-for="(m, i) in messages"
                                :key="i"
                            >
                                <v-expansion-panel-header>
                                    <div>
                                        <p class="mb-1 pt-0">{{ m.name }}</p>
                                        <p class="caption my-0 py-0">
                                            От: {{ m.author.first_name }} {{ m.author.last_name }}
                                        </p>
                                        <p class="caption my-0 py-0">
                                            {{ $moment(m.created).format('DD.MM.YYYY') }}
                                        </p>
                                    </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <div
                                        v-for="(d, j) in m.message"
                                        :key="j"
                                    >
                                        <h3 v-if="d.title">{{ d.title }}</h3>
                                        <p>{{ d.content }}</p>
                                    </div>
                                    <div>
                                        <v-btn
                                            v-if="type === 'message'"
                                            :href="$axios.defaults.baseURL + '/api/landing/message/download/text/' + m.id + '/' + hash2"
                                            class="mt-3 mb-5"
                                        >
                                            Скачать текст
                                        </v-btn>
                                        <v-btn
                                            v-if="type === 'will'"
                                            :href="$axios.defaults.baseURL + '/api/landing/message/download/text/will/' + m.id + '/' + hash2"
                                            class="mt-3 mb-5"
                                        >
                                            Скачать текст
                                        </v-btn>
                                    </div>
                                    <v-card
                                        v-if="!!m.files.audio.length"
                                        elevation="1"
                                        class="mt-5"
                                    >
                                        <v-toolbar flat class="grey lighten-4">
                                            <v-toolbar-title>Аудио</v-toolbar-title>
                                        </v-toolbar>
                                        <v-card-text>
                                            <v-card
                                                v-for="(f, ix) in m.files.audio"
                                                :key="i + ':' + ix"
                                            >
                                                <v-toolbar flat class="grey lighten-4" style="height: 80px">
                                                    <v-btn
                                                        v-if="type === 'message'"
                                                        :href="$axios.defaults.baseURL + '/api/landing/message/download/' + f.id + '/' + hash2"
                                                        icon
                                                        x-small
                                                        class="mx-1"
                                                    >
                                                        <v-icon>mdi-download</v-icon>
                                                    </v-btn>
                                                    <v-btn
                                                        v-if="type === 'will'"
                                                        :href="$axios.defaults.baseURL + '/api/landing/message/download/will/' + f.id + '/' + hash2"
                                                        icon
                                                        x-small
                                                        class="mx-1"
                                                    >
                                                        <v-icon>mdi-download</v-icon>
                                                    </v-btn>
                                                    <v-toolbar-title class="pl-2">
                                                        <div class="mt-5">Вложение {{ ix + 1 }} - Аудиозапись {{ f.name.split('.').pop().toUpperCase() }} <span v-if="f.comment && f.comment.length > 0">({{ f.comment }})</span></div>
                                                        <div class="caption">{{ f.name }}</div>
                                                    </v-toolbar-title>
                                                    <v-spacer />
                                                </v-toolbar>
                                            </v-card>
                                        </v-card-text>
                                    </v-card>
                                    <v-card
                                        v-if="!!m.files.image.length"
                                        elevation="1"
                                        class="mt-5"
                                    >
                                        <v-toolbar flat class="grey lighten-4">
                                            <v-toolbar-title class="font-weight-bold">Изображения</v-toolbar-title>
                                        </v-toolbar>
                                        <v-card-text>
                                            <v-card
                                                v-for="(f, ix) in m.files.image"
                                                :key="i + ':' + ix"
                                            >
                                                <v-toolbar flat class="grey lighten-4" style="height: 80px">
                                                    <v-btn
                                                        @click="updateGallery(m.id, ix + 1)"
                                                        icon
                                                        x-small
                                                        class="mx-1"
                                                    >
                                                        <v-icon>mdi-eye</v-icon>
                                                    </v-btn>
                                                    <v-btn
                                                        v-if="type === 'message'"
                                                        @click="downloadFile"
                                                        :href="$axios.defaults.baseURL + '/api/landing/message/download/' + f.id + '/' + hash2"
                                                        icon
                                                        x-small
                                                        class="mx-1"
                                                    >
                                                        <v-icon>mdi-download</v-icon>
                                                    </v-btn>
                                                    <v-btn
                                                        v-if="type === 'will'"
                                                        @click="downloadFile"
                                                        :href="$axios.defaults.baseURL + '/api/landing/message/download/will/' + f.id + '/' + hash2"
                                                        icon
                                                        x-small
                                                        class="mx-1"
                                                    >
                                                        <v-icon>mdi-download</v-icon>
                                                    </v-btn>
                                                    <v-toolbar-title class="pl-2">
                                                        <div class="mt-5">Вложение {{ ix + 1 + m.files.audio.length }} - Изображение {{ f.name.split('.').pop().toUpperCase() }} <span v-if="f.comment && f.comment.length > 0">({{ f.comment }})</span></div>
                                                        <div class="caption">{{ f.name }}</div>
                                                    </v-toolbar-title>
                                                    <v-spacer/>
                                                </v-toolbar>
                                            </v-card>
                                        </v-card-text>
                                    </v-card>
                                    <v-card
                                        v-if="!!m.files.video.length"
                                        elevation="1"
                                        class="mt-5"
                                    >
                                        <v-toolbar flat class="grey lighten-4">
                                            <v-toolbar-title>Видео</v-toolbar-title>
                                        </v-toolbar>
                                        <v-card-text>
                                            <v-card
                                                v-for="(f, ix) in m.files.video"
                                                :key="i + ':' + ix"
                                            >
                                                <v-toolbar flat class="grey lighten-4" style="height: 80px">
                                                    <v-btn
                                                        v-if="type === 'message'"
                                                        :href="$axios.defaults.baseURL + '/api/landing/message/download/' + f.id + '/' + hash2"
                                                        icon
                                                        x-small
                                                        class="mx-1"
                                                    >
                                                        <v-icon>mdi-download</v-icon>
                                                    </v-btn>
                                                    <v-btn
                                                        v-if="type === 'will'"
                                                        :href="$axios.defaults.baseURL + '/api/landing/message/download/will/' + f.id + '/' + hash2"
                                                        icon
                                                        x-small
                                                        class="mx-1"
                                                    >
                                                        <v-icon>mdi-download</v-icon>
                                                    </v-btn>
                                                    <v-toolbar-title class="pl-2">
                                                        <div class="mt-5">Вложение {{ ix + 1 + m.files.audio.length + m.files.image.length }} - Видеозапись {{ f.name.split('.').pop().toUpperCase() }} <span v-if="f.comment && f.comment.length > 0">({{ f.comment }})</span></div>
                                                        <div class="caption">{{ f.name }}</div>
                                                    </v-toolbar-title>
                                                    <v-spacer />
                                                </v-toolbar>
                                            </v-card>
                                        </v-card-text>
                                    </v-card>
                                    <v-card
                                        v-if="!!m.files.other.length"
                                        elevation="1"
                                        class="mt-5"
                                    >
                                        <v-toolbar flat class="grey lighten-4">
                                            <v-toolbar-title>Разное</v-toolbar-title>
                                        </v-toolbar>
                                        <v-card-text>
                                            <v-card
                                                v-for="(f, ix) in m.files.other"
                                                :key="i + ':' + ix"
                                            >
                                                <v-toolbar flat class="grey lighten-4" style="height: 80px">
                                                    <v-btn
                                                        v-if="type === 'message'"
                                                        :href="$axios.defaults.baseURL + '/api/landing/message/download/' + f.id + '/' + hash2"
                                                        icon
                                                        x-small
                                                        class="mx-1"
                                                    >
                                                        <v-icon>mdi-download</v-icon>
                                                    </v-btn>
                                                    <v-btn
                                                        v-if="type === 'will'"
                                                        :href="$axios.defaults.baseURL + '/api/landing/message/download/will/' + f.id + '/' + hash2"
                                                        icon
                                                        x-small
                                                        class="mx-1"
                                                    >
                                                        <v-icon>mdi-download</v-icon>
                                                    </v-btn>
                                                    <v-toolbar-title class="pl-2">
                                                        <div class="mt-5">Вложение {{ ix + 1 + m.files.audio.length + m.files.image.length + m.files.video.length }} - Документ {{ f.name.split('.').pop().toUpperCase() }} <span v-if="f.comment && f.comment.length > 0">({{ f.comment }})</span></div>
                                                        <div class="caption">{{ f.name }}</div>
                                                    </v-toolbar-title>
                                                    <v-spacer />
                                                </v-toolbar>
                                            </v-card>
                                        </v-card-text>
                                    </v-card>
                                    <div v-if="type === 'will'">
                                        <v-btn
                                            @click="removeMessagePopup(m)"
                                            class="mt-5 mb-0 error"
                                        >
                                            Удалить сообщение
                                        </v-btn>
                                    </div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog
            v-if="remove.data"
            v-model="remove.show"
            max-width="500"
        >
            <v-card>
                <v-card-title class="headline">Удалить сообщение?</v-card-title>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        @click="remove.show = false"
                        :disabled="remove.progress"
                        color="red darken-1"
                        text
                    >
                        Нет
                    </v-btn>
                    <v-btn
                        @click="removeMessage"
                        :loading="remove.progress"
                        color="green darken-1"
                        text
                    >
                        Да
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import FsLightbox from "fslightbox-vue"
import { VueRecaptcha } from 'vue-recaptcha'

export default {
    name: 'MessageController',
    components: {
        FsLightbox,
        VueRecaptcha,
    },
    data () {
        return {
            data: {
                hash1: '',
                hash2: '',
            },
            errors: {
                captcha: [],
                hash1: [],
                hash2: [],
            },
            gallery: {
                key: 0,
                slide: 0,
                sources: [],
                toggler: false,
            },
            galleries: {},
            hash1: '',
            hash2: '',
            messages: [],
            remove: {
                data: null,
                progress: false,
                show: false,
            },
            siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
            stage: 'init',
            type: '',
            wait: false,
            waitHours: 0,
        }
    },
    created () {
        if (this.$route.params.hash) {
            this.data.hash1 = this.$route.params.hash
        }
    },
    methods: {
        onCaptchaExpired () {
            this.$refs.recaptcha.reset()
        },

        read (recaptchaToken) {
            if (this.stage === 'init') {
                this.init(recaptchaToken)
            } else {
                this.confirm(recaptchaToken)
            }
        },

        confirm (recaptchaToken) {
            this.wait = true

            this.errors.captcha = []
            this.errors.hash1 = []
            this.errors.hash2 = []

            this.$axios.post('/api/landing/message/read/confirm', {...this.data, captcha: recaptchaToken})
                .then(res => {
                    if (res.data.success) {
                        this.messages = res.data.data.messages
                        this.hash1 = res.data.data.hash1
                        this.hash2 = res.data.data.hash2

                        this.messages.forEach(m => {
                            this.galleries['lightbox' + m.id] = {
                                key: 'lightbox' + m.id,
                                slide: 0,
                                sources: m.files.image.map(f => {
                                    return this.$axios.defaults.baseURL + '/api/landing/message/preview/will/' + f.id + '/' + this.hash2
                                }),
                                toggler: false,
                            }
                        })

                        this.stage = 'read'

                        this.$refs.recaptcha.reset()
                    } else {
                        this.errors.hash1 = res.data.errors.hash1 || []
                        this.errors.hash2 = res.data.errors.hash2 || []

                        if (this.errors.captcha.length) {
                            this.$refs.recaptcha.reset()
                        }
                    }

                    this.wait = false
                })
        },

        init (recaptchaToken) {
            this.wait = true

            this.errors.hash1 = []

            this.$axios.post('/api/landing/message/read/init', {...this.data, captcha: recaptchaToken})
                .then(res => {
                    if (res.data.success) {
                        this.type = res.data.data.type

                        if (this.type === 'message.off') {
                            this.stage = 'blocked'
                        }

                        if (this.type === 'message.notify') {
                            this.stage = 'wait'
                            this.waitHours = res.data.data.waitHours
                        }

                        if (this.type === 'message.on') {
                            this.messages = [res.data.data.message]
                            this.hash1 = res.data.data.hash1
                            this.hash2 = res.data.data.hash2

                            this.messages.forEach(m => {
                                this.galleries['lightbox' + m.id] = {
                                    key: 'lightbox' + m.id,
                                    slide: 0,
                                    sources: m.files.image.map(f => {
                                        return this.$axios.defaults.baseURL + '/api/landing/message/preview/' + f.id + '/' + this.hash2
                                    }),
                                    toggler: false,
                                }
                            })

                            this.stage = 'read'
                        }

                        if (this.type === 'will') {
                            this.stage = 'confirm'
                        }

                        if (this.stage !== 'read') {
                            this.$refs.recaptcha.reset()
                        }
                    } else {
                        this.errors.hash1 = res.data.errors.hash1 || []

                        if (this.errors.captcha.length) {
                            this.$refs.recaptcha.reset()
                        }
                    }

                    this.wait = false
                })
        },

        downloadFile: function(event) {
            if (!confirm('Подтвердите скачивание файла')) {
                event.preventDefault()
            }
        },

        removeMessage () {
            this.remove.progress = true

            const data = {
                id: this.remove.data.id,
                hash1: this.data.code,
                hash2: this.hash,
            };

            this.$axios.post('/api/landing/message/delete', data)
                .then(() => {
                    this.messages = this.messages.filter((m) => m.id !== this.remove.data.id)
                    this.remove.show = false
                    this.remove.progress = false
                })
                .catch(() => {
                    this.remove.progress = false
                })
        },

        removeMessagePopup (data) {
            this.remove.data = {...data}
            this.remove.show = true
        },

        updateGallery (id, slide) {
            this.gallery.sources = this.galleries['lightbox' + id].sources
            this.gallery.slide = slide
            this.gallery.key = this.galleries['lightbox' + id].key
            this.$nextTick(() => {
                this.gallery.toggler = !this.gallery.toggler
            })
        },

        validate () {
            this.$refs.recaptcha.execute()
        },
    },
}
</script>
