<template>
    <v-container class="mb-12 pb-8">
        <h2 class="section__title">Обратная связь</h2>
        <v-card v-if="create.show">
            <v-form
                autocomplete="off"
                @submit.prevent="createRequest"
            >
                <v-card-text v-if="create.success">
                    <v-alert class="success" dark>
                        Спасибо! Ваше обращение будет рассмотрено в ближайшее время.
                    </v-alert>
                </v-card-text>
                <v-card-actions v-if="create.success">
                    <v-spacer/>
                    <v-btn
                        @click="create.success = false"
                        class="default"
                    >
                        Закрыть
                    </v-btn>
                </v-card-actions>
                <v-card-text class="px-6" v-if="!create.success">
                    <v-select
                        v-model="create.data.subject"
                        :items="subjects"
                        :error-messages="create.errors.subject"
                        item-text="name"
                        item-value="id"
                        label="Тема обращения"
                        placeholder="Выберите тему обращения"
                    />
                    <v-text-field
                        v-model="create.data.name"
                        :error-messages="create.errors.name"
                        name="name"
                        maxlength="100"
                        label="Имя"
                    />
                    <v-text-field
                        v-model="create.data.email"
                        :error-messages="create.errors.email"
                        name="email"
                        label="Почта"
                    />
                    <v-textarea
                        v-model="create.data.message"
                        :error-messages="create.errors.message"
                        name="message"
                        rows="10"
                        counter="2000"
                        maxlength="2000"
                        label="Содержание обращения"
                        placeholder="Введите текст вашего обращения"
                        class="mt-3"
                    />
                </v-card-text>
                <v-card-actions class="py-8 px-6" v-if="!create.success">
                    <v-spacer/>
                    <v-btn
                        @click="create.show = false"
                        :disabled="create.progress"
                        color="red darken-1"
                        text
                    >
                        Отменить
                    </v-btn>
                    <v-btn
                        :loading="create.progress"
                        class="primary"
                        type="submit"
                    >
                        Отправить
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-container>
</template>

<script>
import Vue2Filters from 'vue2-filters'

export default {
    name: "FeedbackController",

    mixins: [Vue2Filters.mixin],

    data () {
        return {
            create: {
                data: {
                    subject: null,
                    email: '',
                    message: '',
                    name: '',
                },
                errors: {},
                progress: false,
                show: true,
                success: false,
            },
            subjects: [
                {
                    id: 'signup',
                    name: 'Регистрация',
                    icon: 'mdi-account-plus',
                },
                {
                    id: 'signin',
                    name: 'Доступ в личный кабинет',
                    icon: 'mdi-badge-account',
                },
                {
                    id: 'other',
                    name: 'Прочее',
                    icon: 'mdi-frequently-asked-questions',
                },
            ],
        }
    },

    methods: {
        createRequest () {
            this.create.progress = true

            this.$axios.post('/api/landing/feedback/create', this.create.data)
                .then(res => {
                    if (res.data.success) {
                        this.create.data.subject = null
                        this.create.data.email = ''
                        this.create.data.message = ''
                        this.create.data.name = ''
                        this.create.errors = {}
                        this.create.success = true
                    } else {
                        this.create.errors.subject = res.data.errors.subject || []
                        this.create.errors.email = res.data.errors.email || []
                        this.create.errors.message = res.data.errors.message || []
                        this.create.errors.name = res.data.errors.name || []
                    }

                    this.create.progress = false
                })
                .catch(() => {
                    this.create.progress = false
                })
        },
    },
}
</script>
