<template>
    <v-container class="mb-10 pb-10">
        <v-card>
            <v-toolbar
                flat
                dense
                class="pa-auto grey lighten-4"
            >
                <v-toolbar-title>Пользовательское соглашение</v-toolbar-title>
            </v-toolbar>
            <v-card-text
                class="article"
            >
                <div v-html="article.contents"></div>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: "AgreementController",
    data () {
        return {
            article: null,
            loading: true,
        }
    },
    created () {
        this.searchArticle()
    },
    methods: {
        searchArticle () {
            this.loading = true

            this.$axios.post('/api/landing/article/search', {url: this.$router.currentRoute.path})
                .then(res => {
                    if (res.data.success) {
                        this.article = res.data.data
                    }

                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        },
    },
}
</script>

<style lang="scss">
.article {
    img {
        max-width: 100%;
    }
}
</style>
