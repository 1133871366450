import Vue from 'vue';
import Vuetify, { VAlert } from 'vuetify/lib';
import ru from 'vuetify/es5/locale/ru'

Vue.use(Vuetify, {
    components: {
        VAlert,
    },
});

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    lang: {
        locales: {ru},
        current: 'ru',
    },
    theme: {
        defaultTheme: 'light',
        themes: {
            light: {
                primary: '#fa5d18',
            },
        },
    },
});
